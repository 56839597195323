import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button, Chip, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import ProgressTrackerStats from './progressTrackerStats';
import Dropdown from './dropdowns/dropdown';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';
import { useNavigate } from 'react-router-dom';
import {useRecoilState, useRecoilValue} from "recoil";
import {progressTrackerAtom} from "./atoms/progressTracketAtoms";
import {getTrackerStats} from "../api/stats";
import { userForAdminPanelAtom, userInfoAtom } from '../recoil/atoms/userAtom';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const Layout = styled('div')({
    padding: 17,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    background: 'rgba(59, 122, 246, 0.14)',
    borderRadius: 40,
});

const Header = styled('header')({
    padding: '10px 10px 5px 10px',

    // display: 'grid',
    // gridTemplateColumns: '1fr 1fr',
    display:"flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    '& nav': {
        justifySelf: 'end',
    },
});

const TypographyHeader = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '100%',
    letterSpacing: '0.01em',

    color: colors.darkBlue,
});

const Date = styled(Chip)({
    marginTop: 5,
    marginBottom: 27,
});

const Stats = styled('main')({
    maxHeight: 128,

    display: 'flex',
    gap: 2,
});

const ActionButton = styled(Button)({
    textTransform: 'none',
    marginTop: 17,

    height: 52,

    flexShrink: 0,

    background: colors.main,
    color: colors.white,
    borderRadius: 23,

    fontSize: 12,

    '& svg': {
        marginLeft: 9,
    },
});

const ProgressTracker = ({ mainPanel, hasKssData }) => {
    const dropdownOptions = [
        {
            title: 'Weekly',
            date: '23.05—27.05',
        },
        {
            title: 'Monthly',
            date: '23.05—27.05',
        },
        {
            title: 'Daily',
            date: '23.05—27.05',
        },
    ];

    const navigate = useNavigate();
    const [data, setData] = useRecoilState(progressTrackerAtom);
    const user = useRecoilValue(userInfoAtom);
    const adminUser = useRecoilValue(AdminUserAtom);

    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);
    
    useEffect(() => {
        if (adminUser.appAdminPanel) {
            getTrackerStats("session", userForAdminPanel.id, userForAdminPanel.teams?.[0].id)
                .then(({ data }) => {
                    setData(data)
                })
                .catch((err) => {
                    console.error("Ошибка получения статистики трекера", err);
                });
        } else {
            getTrackerStats("session")
                .then(({ data }) => {
                    setData(data)
                })
                .catch((err) => {
                    console.error("Ошибка получения статистики трекера", err);
                });
        }
    }, []);

    if (!hasKssData) {
        return null;
    }


    return (
       <Layout>
            <Header>
                <TypographyHeader>Ежедневный Трекер</TypographyHeader>
                {/*<Dropdown options={dropdownOptions} />*/}
                <Date label={data?.period} size="small" variant="lightBlue" />
            </Header>
            <Stats>
                <ProgressTrackerStats items={data?.Keep} maxScore={data["max_score"]} type={'Keep'} />
                <ProgressTrackerStats items={data?.Stop} maxScore={data["max_score"]} type={'Stop'} />
                <ProgressTrackerStats items={data?.Start} maxScore={data["max_score"]} type={'Start'} />
            </Stats>
            {mainPanel && hasKssData && (
                <ActionButton
                    variant="large"
                    endIcon={<ArrowRight />}
                    onClick={() => navigate('/tracker')}
                >
                    К трекеру
                </ActionButton>
            )}
        </Layout>
    )

};

export default ProgressTracker;
