import {styled} from "@mui/system";
import {TextField, Typography} from "@mui/material";
import React from "react";

const Wrapper = styled('div')({
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    alignItems: 'center',
    minHeight: '300px',
    width: '100%',
    maxWidth: '100%',
});

const Title = styled(Typography)({
    fontSize: '30px'
})

const FieldsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
});

const Label = styled(Typography)({
    fontSize: 24,
    lineHeight: "120%",
});

const Input = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 10
});

const QuizPersonalData = ({name, surname, onChangeName, onChangeSurname}) => {
    return (
        <Wrapper>
            <Title variant={"h1"}>
                Пройдите опрос
            </Title>
            <FieldsWrapper>
                <Input>
                    <Label variant={'h1'}>
                        Имя
                    </Label>
                    <TextField value={name}
                               onChange={onChangeName}
                               placeholder={"Введите имя"}
                    />
                </Input>
                <Input>
                    <Label variant={'h1'}>
                        Фамилия
                    </Label>
                    <TextField value={surname}
                               onChange={onChangeSurname}
                               placeholder={"Введите фамилию"}
                    />
                </Input>
            </FieldsWrapper>
        </Wrapper>
    )
};

export default QuizPersonalData;