export function getBarHeight(score, maxScore, zeroHeight = 8) {
   if (score === 0) {
      return zeroHeight + '%';
   }

   if (!score) {
      return 0;
   }

   return (score / maxScore) * 100 + "%";
}