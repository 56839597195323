import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import TelegramSubscription from '../components/telegramSubscription';

import { useSearchParams } from 'react-router-dom';
import { subscribeToTelegram } from '../api/telegram';

const TelegramSubscriptionPage = () => {
    const [searchParams] = useSearchParams();
    const telegramId = searchParams.get('telegram_id');

    const [data, setData] = useState('');

    useEffect(() => {
        subscribeToTelegram(telegramId)
            .then(({ data }) => setData(data))
            .catch((e) => setData(e.response.data));
    }, []);

    return (
        <Layout link={'/'}>
            <TelegramSubscription data={data} />
        </Layout>
    );
};

export default TelegramSubscriptionPage;
