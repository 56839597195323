import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import {Typography} from "@mui/material";
import {styled} from "@mui/material";
import {colors} from "../styles/globalStyles";
import {getAllCompaniesData} from "../api/adminPanel";
import CompanyCard from "../components/pages/adminPanel/companyCard";
import { getAvatar } from "../api/user";
import { userInfoAtomArr } from "../recoil/atoms/userAtom";
import { useRecoilState } from "recoil";

const Title = styled(Typography)({
    fontSize: 24,
    "& .accent":{
        color: colors.main
    }
})

const CompaniesWrap = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 17,
})
const AdminPanel = ()=>{
    const [companiesData, setCompaniesData] = useState(null);
    const [, setUserInfo] = useRecoilState(userInfoAtomArr);

    useEffect(() => {
        getAllCompaniesData()
            .then(r=>{
                setCompaniesData(r.data)
            })
            .catch((err) => {
                console.error("Ошибка получения данных компаний", err);
            });
        
        getAvatar()
            .then(({ data }) => {
                const avatar = `https://app.intg101.work${data}`;
                localStorage.setItem('avatar', avatar);

                setUserInfo((userData) => ({ ...userData, avatar: avatar }));
            })
            .catch((err) => {
                console.error("Ошибка получения аватара", err);
            });
    }, []);

    return(
        <Layout
        //  link={"/"}
         >
            <Title variant={"h2"}>
                Мои <span className={"accent"}> компании</span>
            </Title>
            <CompaniesWrap>
                {companiesData?.map(company=> <CompanyCard data={company} key={company.name}/>)}
            </CompaniesWrap>
        </Layout>
    )
}

export default AdminPanel