import React from 'react';
import { Button, Chip, styled, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import Bg from '../images/bg_button_wide.png';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';

const Layout = styled('div')({
    padding: 17,
    width: '50%',
    flexShrink: 0,
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    background: `url(${Bg}), radial-gradient(140.07% 100% at 0% 100%, #9C17CB 53.17%, #CB1799 100%)`,
    backgroundSize: 'cover',
    borderRadius: 40,
});

const TypographyAboveChip = styled(Typography)({
    padding: 10,

    fontWeight: 500,
    fontSize: 14,
    lineHeight: '100%',
    color: colors.white,
    opacity: 0.6,
});

const StyledChip = styled(Chip)({
    marginBottom: 17,
    padding: 17,

    width: '100%',
    height: 87,
    display: 'block',

    background: colors.transparentWhite,
    borderRadius: 23,

    '& span': {
        padding: 0,
    },
});

const Time = styled(Typography)({
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '110%',
    color: colors.white,
});

const DateTypography = styled(Typography)({
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '110%',
    color: colors.white,
});

const StyledButton = styled(Button)({
    width: '100%',
    height: 52,

    background: colors.white,
    backgroundSize: 'cover',
    borderRadius: 23,

    fontWeight: 700,
    fontSize: 12,
    lineHeight: '100%',
    color: '#BD17CB',

    '& span': {
        marginLeft: 5,
    },
    '&:hover': {
        backgroundColor: colors.white,
    },
});

const MySessionsNextSession = ({ link, hours, date }) => {
    return (
        <Layout>
            <div>
                <TypographyAboveChip className="sessions">Следующая:</TypographyAboveChip>
                <StyledChip
                    label={
                        <>
                            <Time>{hours}</Time>
                            <DateTypography>{date}</DateTypography>
                        </>
                    }
                />
            </div>
            <StyledButton
                target="_blank"
                rel="noopener noreferrer"
                href={link}
                endIcon={<ArrowRight fill="#BD17CB" />}
            >
                Подключиться
            </StyledButton>
        </Layout>
    );
};

export default MySessionsNextSession;
