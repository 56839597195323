import React from 'react';
import {Card, CardActionArea, Chip, IconButton, Skeleton, styled, Typography} from '@mui/material';
import {colors} from '../styles/globalStyles';
import Dropdown from './dropdowns/dropdown';
import Bg1 from '../images/bg/bg_1.svg';
import Bg25 from '../images/bg/bg_25.svg';
import Bg50 from '../images/bg/bg_50.svg';
import Bg75 from '../images/bg/bg_75.svg';
import Bg100 from '../images/bg/bg_100.svg';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as ArrowRight} from "../images/arrow_right.svg";

const Layout = styled(CardActionArea)(({background}) => ({
    padding: 27,
    background,
    backgroundSize: 'cover',
    letterSpacing: '0.01em',
    borderRadius: 40,
}));

const Header = styled('header')({
    marginBottom: 30,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const FlexColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
});

const MyProgress = styled(Typography)({
    fontSize: 24,
    fontWeight: 700,
    color: colors.white,
});

const Percentage = styled(Typography)({
    marginBottom: 27,

    fontSize: 78,
    fontWeight: 700,
    lineHeight: '100%',
    color: colors.white,
});

const ProgressComponent = ({percentage, period}) => {
    const dropdownOptions = [
        {
            title: 'Weekly',
            date: '23.05—27.05',
        },
        {
            title: 'Monthly',
            date: '23.05—27.05',
        },
        {
            title: 'Daily',
            date: '23.05—27.05',
        },
    ];

    const navigate = useNavigate();
    const getBg = (percentage) => {
        const bg = {
            '1%': `url(${Bg1}), linear-gradient(180deg, #E44E90 0%, #EF6E77 100%)`,
            '25%': `url(${Bg25}), linear-gradient(180deg, #F3AB1D 0%, #E44993 100%)`,
            '50%': `url(${Bg50}), linear-gradient(180deg, #EAE21D -11.02%, #F7A70B 100%)`,
            '75%': `url(${Bg75}), linear-gradient(180deg, #09DBAF -20.82%, #FBC60D 108.98%)`,
            '100%': `url(${Bg100}), linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), linear-gradient(180deg, #0FBA68 0%, #1FD5AE 100%)`,
        };
        if (percentage < 25) return bg['1%'];

        if (percentage >= 25 && percentage < 50) return bg['25%'];

        if (percentage >= 50 && percentage < 75) return bg['50%'];

        if (percentage >= 75 && percentage < 100) return bg['75%'];

        if (percentage === 100) return bg['100%'];
    }

    if(percentage == null || period == null) {
        return (
            <Skeleton variant={"rectangular"} sx={{width: "100%", height: 261, borderRadius: "40px"}}/>
        )
    }

    return (
            <Card onClick={() => navigate('/progress')}>
                <Layout background={getBg(percentage)}>
                      <Header>
                        <MyProgress>Мой Прогресс</MyProgress>
                          <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M23 0C10.2975 0 0 10.2975 0 23V29C0
                              41.7025 10.2975 52 23 52H29C41.7025 52 52 41.7025 52 29V23C52 10.2975 41.7025 0 29
                              0H23ZM30.4824 26.4043L24.2949 32.5918L23.4863 31.7832L29.2695 26L23.4863 20.2168L24.2949
                              19.4082L30.4824 25.5957L30.8691 26L30.4824 26.4043Z" fill="white"/>
                          </svg>
                    </Header>
                    <FlexColumn>
                        <Percentage>{percentage}%</Percentage>
                        <Chip variant="transparentWhite" size="small" label={period}/>
                    </FlexColumn>
                </Layout>
            </Card>
    );
};

export default ProgressComponent;
