import { atom, selector } from 'recoil';
import { getTeamMaxScore } from '../../api/maxScore';

export const maxScoreSelector = selector({
    key: 'maxScoreSelector',
    get: async () => {
        const response = await getTeamMaxScore()
        if (response.data) {
            return response.data
        }
        return 4
    }
})

export const maxScoreAtom = atom({
    key: 'maxScore',
    default: maxScoreSelector,
})