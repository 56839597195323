import { atom, selector } from 'recoil';
import { getScoresTimeLine } from '../../api/stats';
import { getKssTrackerData } from '../../util/kssTracker';

export const kssTrackerDataSelector = selector({
    key: 'kssTrackerData',
    get: getKssTrackerData,
    set: ({ get, set }, data) => {
        set(kssTrackerDataAtom, data)
        let timeLine = get(timeLineDataAtom)
        const today = new Date().toISOString().substring(0, 10);
        let idx = timeLine.periods[timeLine.periods.length-1].days.findIndex(item => {
            return item.date.substring(0, 10) === today
        })
        if (idx === -1) return
        let newKssScores = {}
        data.map(item => {
            newKssScores[item.status.toLowerCase()] = item.score
        })
        //Fucking matryoshka {
        let copyPeriods = [...timeLine.periods]
        let copyPeriodsEl = {...copyPeriods[copyPeriods.length-1]}
        let copyDays = [...copyPeriodsEl.days]
        let copyOneDay = {...copyDays[idx]}
        copyOneDay.scores = newKssScores
        copyDays[idx] = copyOneDay
        copyPeriodsEl.days = copyDays
        copyPeriods[copyPeriods.length-1] = copyPeriodsEl
        let copyTimeLine = {...timeLine, periods: copyPeriods}
        //}
        set(timeLineDataAtom, copyTimeLine)
    }
})


export const kssTrackerDataAtom = atom({
    key: 'kssTracker',
    default: kssTrackerDataSelector,
})


const timeLineDataSelector = selector({
    key: 'timeLineDataSelector',
    get: async () => {
        const userIdForAdminPanel = localStorage.getItem('user-id-for-admin-panel');
        const response = await getScoresTimeLine(userIdForAdminPanel || null);
        if (response.data?.periods?.length === 0) {
            return []
        }
        const timeLineData = response.data
        return timeLineData
    }
})


export const timeLineDataAtom = atom({
    key: 'timelineData',
    default: timeLineDataSelector
})

export const selectedDataAtom = atom({
    key: 'selectedData',
    default: null
})

export const selectedDateAtom = atom({
    key: 'selectedDate',
    default: null
})