import React from 'react';
import { InputBase } from '@mui/material';
import { ReactComponent as UserIcon } from '../../images/user_icon.svg';
import { inputBaseStyles, InputWrap } from './decoratedInput';

const LoginInput = ({ value, onChange }) => (
    <InputWrap>
        <UserIcon style={{ flexShrink: 0 }} />
        <input
            data-testid="loginInput"
            type={'text'}
            placeholder={'login'}
            style={inputBaseStyles}
            value={value}
            onChange={onChange}
        />
    </InputWrap>
);

export default LoginInput;
