import { Skeleton } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import LayoutSkeleton from './layoutSkeleton'

const Container = styled('div')({
    display: "flex",
    flexDirection: "column",
    gap: 17,
})

const TwoComponents = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    height: 217
})

const MySessionsSkeleton = ({link}) => {
    return (
        <LayoutSkeleton link={link}>
            <Container>
                <Skeleton variant={"text"} sx={{width: "100%", fontSize: 24}} />

                <Skeleton variant={"rectangular"} sx={{height: 106, width: "100%", borderRadius: 40}} />
                <Skeleton variant={"rectangular"} sx={{height: 106, width: "100%", borderRadius: 40}} />
                    
                <TwoComponents>
                    <Skeleton variant={"rectangular"} sx={{height: "100%", width: "50%", borderRadius: '40px'}} />
                    <Skeleton variant={"rectangular"} sx={{height: '100%', width: "50%", borderRadius: '40px'}} />
                </TwoComponents>
                <Skeleton variant={"rectangular"} sx={{height: '417px', width: "100%", borderRadius: '40px'}} />

            </Container>
        </LayoutSkeleton>
    )
}

export default MySessionsSkeleton