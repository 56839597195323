import { axiosInstance } from "./axiosInstance";

export const createFeedback = (scoreId, text) => axiosInstance({
    method: "POST",
    url: `feedback/`,
    data:{
        score: scoreId,
        text: text
    }
})

export const updateFeedback = (scoreId, text) => axiosInstance({
    method: "PUT",
    url: `feedback/`,
    data:{
        score: scoreId,
        text: text
    }
})

export const getBehaviorFeedback = (id, user) => axiosInstance.get(`feedback/get_from_behavior/`,{
    params:{
        behavior: id,
        user: user,
    }
})

export const getSessionHistory = (id, user) => axiosInstance.get('feedback/sessions_history',{
    params:{
        behavior: id,
        user: user,
    }
})
