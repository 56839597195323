import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { getTeamStats } from '../api/adminPanel';
import { useParams } from 'react-router-dom';
import SearchUserProgress from '../components/searchUserProgress';
import { CardActionArea, IconButton, styled, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';
import UserCreateModal from '../components/pages/adminPanel/userCreateModal';

const TeamGrid = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 2,
});

const AddUserButtonWrap = styled(CardActionArea)({
    marginBottom: 17,
    paddingInline: '17px 10px',
    height: 72,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,

    background: 'rgba(59, 122, 246, 0.07)',
    borderRadius: 23,
});

const Label = styled(Typography)({
    marginRight: 'auto',

    fontWeight: 700,
    fontSize: 20,
    lineHeight: '125%',
});

const StyledIconButton = styled(IconButton)({
    width: 52,
    height: 52,

    background: colors.transparentMain,
    borderRadius: 13,
});

const TeammatesPage = () => {
    const { teamId, companyId } = useParams();

    const [state, setState] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null)

    function handleSelectUser(user) {
        user.companyId = companyId;
        
        setSelectedUser(user);
    }

    useEffect(() => {
        if (!open) {
            getTeamStats(teamId)
                .then((r) => {
                    setState(r.data)
                })
                .catch((err) => {
                    console.error("Ошибка получения статистики команды", err);
                });
            setSelectedUser(null)
        }
    }, [teamId, open]);


    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            <AddUserButtonWrap onClick={() => setOpen(true)}>
                <Label>Добавить пользователя</Label>
                <StyledIconButton onClick={() => setOpen(true)}>
                    <ArrowRight fill={colors.main} />
                </StyledIconButton>
            </AddUserButtonWrap>
            <TeamGrid>
                {state.map((user) => (
                    <SearchUserProgress key={user.id} userData={user} admin={true} setOpen={setOpen} setSelectedUser={handleSelectUser}/>
                ))}
            </TeamGrid>
            <UserCreateModal open={open} onClose={() => setOpen(false)} teamId={teamId} user={selectedUser} />
        </Layout>
    );
};

export default TeammatesPage;
