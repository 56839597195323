import React, { useEffect, useState } from 'react';
import { Chip, styled, Typography, Skeleton, Button } from '@mui/material';
import { colors } from '../styles/globalStyles';
import Dropdown from './dropdowns/dropdown';
import { getWeekDayStats } from '../api/stats';
import { useRef } from 'react';
import FeedbackModal from './feedbackModal';
import { getColorType } from '../pages/trackerPage';
import { useRecoilValue } from 'recoil';
import { userForAdminPanelAtom } from '../recoil/atoms/userAtom';

const Layout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    background: 'rgba(59, 122, 246, 0.14)',
    borderRadius: 40,

    color: colors.darkBlue,

    overflow: 'hidden',
});

const Header = styled('header')({
    padding: '17px 27px 27px 17px',
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    marginTop: 11,
});

const TypographyHeader = styled(Typography)({
    paddingLeft: "10px",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '100%',
    letterSpacing: '0.01em',
});

const Calendar = styled('main')({
    maxHeight: 221,
    borderRadius: "0 0 40px 40px",
    display: 'flex',
    gap: 2,

    overflowX: 'auto',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
    // Firefox scroll bar hidden
    scrollbarWidth: 'none',
});

const CalendarColumn = styled('div')({
    minWidth: '18.5%',

    display: 'flex',
    flexDirection: 'column',
});

const TypographySection = styled('section')({
    marginBottom: 10,

    textAlign: 'center',
    letterSpacing: '0.01em',
});

const TypographyDate = styled(Typography)(({ isRed }) => ({
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '100%',
    
    color: isRed ? 'red' : 'inherit',
}));

const TypographyDay = styled(Typography)(({ isRed }) => ({
    fontSize: 10,

    color: isRed ? 'red' : 'inherit',
}));

const PeriodChip = styled(Chip)({
    position: "absolute",
    right: 28,
    top: 30,
});

const ColoredBlocks = styled('section')({
    height: 174,
    justifyContent: "end",
    display: 'flex',
    flexDirection: 'column',

    gap: 2,
});

const ColoredBlock = styled(Button)({
    content: '""',
    borderRadius: 17,

    '&:last-child': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
});

const ProgressTrajectory = () => {
    const today = new Date().toDateString()
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState(null)
    const [currentPeriod, setCurrentPeriod] = useState(null)
    const [dropdownData, setDropdownData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedBehavior, setSelectedBehavior] = useState(null)
    const todayRef = useRef(null)
    const calendarRef = useRef(null)
    const [firstScoreIndex, setFirstScoreIndex] = useState(null); // Индекс дня, в который пользователь впервые поставил оценку

    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);

    useEffect(() => {
        getWeekDayStats(userForAdminPanel.id, userForAdminPanel.teams?.[0].id)
            .then((res) => {
                setData(res.data);
                setCurrentData(res.data[res.data.length-1])
                let dropdown = []
                res.data.map(item => {
                    let data = {}
                    data.name = item.period
                    data.interval = item.name
                    dropdown.push(data)
                })
                setDropdownData(dropdown)
                setCurrentPeriod(dropdown[0])
            })
            .catch((err) => {
                console.error("Ошибка получения статистики", err);
            });
    }, []);

    useEffect(() => {
            calendarRef?.current?.scrollTo({
                left: String(todayRef?.current?.getBoundingClientRect().left),
                behavior: "instant",
            })
    }, [data]);

    useEffect(() => {
        if (currentPeriod){
            let current = data.find(item => item.name === currentPeriod.interval)
            setCurrentData(current)
        }
    }, [currentPeriod])

    useEffect(() => {
        if (!currentData) {
            return;
        }

        for (let i = 0; i < currentData.content.length; i++) {
            const dayItem = currentData.content[i];

            if (Boolean(dayItem.kss.Keep) || Boolean(dayItem.kss.Start) || Boolean(dayItem.kss.Stop)) {
                setFirstScoreIndex(i);
                return;
            }
        }
    }, [currentData]);

    const handleKssClick = (e, beh) => {
        e.preventDefault()
        setSelectedBehavior(beh)
        setModalOpen(true)
    }


    return data.length > 0 ? (
        <Layout>
            <Header>
                <TypographyHeader >Тренд — KSS</TypographyHeader>
                {dropdownData.length > 0 ? <Dropdown options={dropdownData} setSelectedOption={setCurrentPeriod} /> : null}
            </Header>
            <Calendar ref={calendarRef}>
                <>
                    {currentData.content.map((item, index) => (
                        <CalendarColumn key={index}>
                            <TypographySection>
                                <TypographyDate
                                    ref={new Date(item.date).toDateString() === today ? todayRef : null}
                                    isRed={['Сб', 'Вс'].includes(item.weekday)}
                                >
                                    {item.day}
                                </TypographyDate>
                                <TypographyDay
                                    isRed={['Сб', 'Вс'].includes(item.weekday)}
                                >
                                    {item.weekday}
                                </TypographyDay>
                            </TypographySection>
                            <ColoredBlocks>
                                {item.kss.Keep !== null && (
                                    <ColoredBlock
                                        onClick={e => handleKssClick(e, {...item.kss.Keep.behavior, status: "keep"})}
                                        style={{
                                            background: index === firstScoreIndex ? colors.gradient.bg.gray : colors.gradient.bg.keep,
                                            height: `${(item.kss.Keep.score / currentData.max_score) * (100 / 3) || 0}%`,
                                        }}
                                    />
                                )}
                                {item.kss.Stop !== null && (
                                    <ColoredBlock
                                        onClick={e => handleKssClick(e, {...item.kss.Stop.behavior, status: "stop"})}
                                        style={{
                                            background: index === firstScoreIndex ? colors.gradient.bg.gray : colors.gradient.bg.stop,
                                            height: `${(item.kss.Stop.score / currentData.max_score) * (100 / 3) || 0}%`,
                                        }}
                                    />
                                )}
                                {item.kss.Start !== null && (
                                    <ColoredBlock
                                        onClick={e => handleKssClick(e, {...item.kss.Start.behavior, status: "start"})}
                                        style={{
                                            background: index === firstScoreIndex ? colors.gradient.bg.gray : colors.gradient.bg.start,
                                            height: `${(item.kss.Start.score / currentData.max_score) * (100 / 3) || 0}%`,
                                        }}
                                    />
                                )}
                            </ColoredBlocks>
                        </CalendarColumn>
                    ))}
                </>
            </Calendar>
            {modalOpen ? <FeedbackModal
                    behaviorData={selectedBehavior}
                    open={modalOpen}
                    setIsOpen={setModalOpen}
                    background={getColorType(selectedBehavior?.status)}
                />
             : null}
        </Layout>
    ) : <Skeleton variant={'rectangular'} sx={{ height: 221, borderRadius: '40px' }} />;
};

export default ProgressTrajectory;
