import React from 'react';
import { styled, Typography } from '@mui/material';

const Wrapper = styled('div')({
    height: '100vh',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
});

const TypographyMain = styled(Typography)({
    fontSize: 32,

    '@media (min-width: 767px)': {
        fontSize: 48,
    },
});

const TelegramSubscription = ({ data }) => {
    return (
        <Wrapper>
            <TypographyMain>{data}</TypographyMain>
        </Wrapper>
    );
};

export default TelegramSubscription;
