import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { bgColors, colors, theme } from '../styles/globalStyles';
import LoginInput from '../components/inputs/loginInput';
import PasswordInput from '../components/inputs/passwordInput';
import Bg from '../images/bg.png';
import { ReactComponent as INTG101 } from '../images/intg101_logo.svg';
import { ReactComponent as SignIn } from '../images/sign_in.svg';
import { ReactComponent as StartTrackingArrows } from '../images/start_tracking_arrows.svg';
import { ReactComponent as ArrowLeft } from '../images/left_arrow.svg';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {authTelegramUser, authUser, getUserData} from '../api/auth';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';

const Layout = styled('div')(({ background }) => ({
    padding: 17,
    height: '100vh',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

    background: `${background}, url(${Bg})`,
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    borderRadius: 0,

    color: colors.white,

    [theme.breakpoints.up('xs')]: {
        maxWidth: 390,
        margin: '0 auto',
        borderRadius: 23,
    },
}));

const Header = styled('header')({
    paddingTop: 11,
    paddingLeft: 11,

    display: 'flex',
    alignItems: 'center',
    gap: 11,
});

const TypographySection = styled('section')({
    paddingInline: 10,
});

const TypographyMainOpaque = styled(Typography)({
    fontSize: 24,
    fontWeight: 400,
    opacity: 0.6,
    color: colors.white,
    marginBottom: 17,
});

const TypographyMain = styled(Typography)({
    fontSize: 58,
    fontWeight: 800,
    lineHeight: '110%',
    color: colors.white,
});

const TypographyComment = styled(Typography)({
    fontSize: 25,
    fontWeight: 500,
    lineHeight: '110%',
    color: colors.white,
});

const ThreeItemsSection = styled('section')({
    display: 'flex',
    gap: 2,
});

const RoundItem = styled('div')({
    padding: '27px 10px',
    width: '100%',

    background: 'rgba(255, 255, 255, 0.14)',
    borderRadius: 40,

    textAlign: 'center',

    // [theme.breakpoints.up('sm')]: {
    //     padding: '72.5px 92.5px',
    // },
});

const TypographyRoundItem = styled(Typography)({
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '128.5%',
    color: colors.white,
});

const TypographyFooter1 = styled(Typography)({
    textAlign: 'center',

    fontSize: 15,
    fontWeight: 400,
    lineHeight: '150%',
    opacity: 0.6,
    color: colors.white,
});

const ErrorText = styled(Typography)({
    marginLeft: 10,
    fontSize: 15,
    fontWeight: 500,
    color: colors.red,
    lineHeight: '150%',
});

const TypographyFooter2 = styled('span')({
    marginLeft: 5,
    fontWeight: 700,
    color: colors.white,
});

const WelcomePage = () => {
    const [logInForm, setLogInForm] = useState(false);
    const isLogged =
        localStorage.getItem('auth-token') || localStorage.getItem('refresh-token') ? true : false;
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const setUserInfo = useSetRecoilState(userInfoAtomArr);
    const setAdminUser = useSetRecoilState(AdminUserAtom);

    let navigate = useNavigate();

    const bgColor = bgColors.purpleBlue;
    const color = colors.purple;

    const textData = {
        welcome: {
            opaque: 'Добро пожаловать!',
            main: 'Трекер для развития вашей команды',
            comment: 'Начните изменения прямо сейчас!',
        },
        signIn: {
            opaque: 'Войти',
            main: 'Чтобы начать изменения',
        },
    };
    const threeRoundItemsData = [
        'Оценивайте своё поведение',
        'Следите за прогрессом',
        'Обсуждайте с коллегами',
    ];
    const location = useLocation();

    useEffect(() => {
        setLogInForm(location.state?.state?.form ?? false);
    }, [location]);

    useEffect(() => {
        if (window.Telegram?.WebApp?.initDataUnsafe?.user?.id && !isLogged) {
            if (window?.Telegram?.WebApp?.initDataUnsafe?.start_param) {
                navigate('/quiz');
            } else {
                authTelegramUser(window.Telegram?.WebApp?.initDataUnsafe?.user?.id)
                    .then(({ data }) => {
                        localStorage.setItem('auth-token', data.access);
                        localStorage.setItem('refresh-token', data.refresh);
                        // getUserData()
                        //     .then((res) => {
                        //         var newInfo = {
                        //             username: res.data.username,
                        //             firstName: res.data.first_name,
                        //             userId: res.data.id,
                        //             avatar: '',
                        //             teamName: res.data.team.name,
                        //             teamId: res.data.team.id,
                        //         };
                        //
                        //         setUserInfo(newInfo);
                        //         localStorage.setItem('user-id', res.data.id);
                        //         localStorage.setItem('team-id', res.data.team.id);
                        //         localStorage.setItem('username', res.data.username);
                        //
                        //         if (res.data.is_staff || res.data.app_admin_panel === true) {
                        //             setAdminUser({
                        //                 isStaff: res.data.is_staff,
                        //                 appAdminPanel: res.data.app_admin_panel,
                        //             });
                        //             navigate('/admin');
                        //             return;
                        //         }
                        //
                        //         navigate('/main');
                        //     })
                        //     .catch((error) => {
                        //         console.error('Ошибка получения пользователя', error);
                        //     });
                    });
            }
        }
    }, []);

    const checkIsLogged = () => {
        if (!isLogged) {
            setLogInForm(true);
            return;
        }
        getUserData()
            .then((r) => {
                if (r.data.is_staff || r.data.app_admin_panel === true) {
                    setAdminUser({
                        isStaff: r.data.is_staff,
                        appAdminPanel: r.data.app_admin_panel,
                    });
                    navigate('/admin');
                    return;
                }
                navigate('/main');
            })
            .catch((error) => {
                console.error('Ошибка получения пользователя', error);
            });
    };

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSignIn = (e) => {
        e.preventDefault();
        setError(null);
        authUser(userName, password)
            .then(({ data }) => {
                localStorage.setItem('auth-token', data.access);
                localStorage.setItem('refresh-token', data.refresh);
                localStorage.removeItem('user-id-for-admin-panel');
                getUserData()
                    .then((res) => {
                        var newInfo = {
                            username: res.data.username,
                            firstName: res.data.first_name,
                            userId: res.data.id,
                            avatar: '',
                            teamName: res.data.team.name,
                            teamId: res.data.team.id,
                        };

                        setUserInfo(newInfo);
                        localStorage.setItem('user-id', res.data.id);
                        localStorage.setItem('team-id', res.data.team.id);
                        localStorage.setItem('username', res.data.username);

                        if (res.data.is_staff || res.data.app_admin_panel === true) {
                            setAdminUser({
                                isStaff: res.data.is_staff,
                                appAdminPanel: res.data.app_admin_panel,
                            });
                            navigate('/admin');
                            return;
                        }

                        navigate('/main');
                    })
                    .catch((error) => {
                        console.error('Ошибка получения пользователя', error);
                    });
            })
            .catch((err) => {
                console.error('Ошибка авторизации пользователя', err);
                if (
                    err.response.data.detail ===
                    'No active account found with the given credentials'
                )
                    setError('Invalid username or password');
            });
    };

    const handleRegistration = (e) => {
        navigate('/registration');
    };

    return (
        <Layout background={bgColor}>
            <Header>
                {logInForm && (
                    <Button
                        startIcon={<ArrowLeft fill={color} />}
                        variant="light"
                        onClick={() => setLogInForm(false)}
                        sx={{ color }}
                    >
                        Назад
                    </Button>
                )}
                <INTG101 />
            </Header>
            <TypographySection>
                <TypographyMainOpaque>
                    {logInForm ? textData.signIn.opaque : textData.welcome.opaque}
                </TypographyMainOpaque>
                <TypographyMain>
                    {logInForm ? textData.signIn.main : textData.welcome.main}
                </TypographyMain>
            </TypographySection>
            {logInForm ? (
                <>
                    {error ? <ErrorText>{error}</ErrorText> : null}
                    <section>
                        <LoginInput value={userName} onChange={(e) => handleUserNameChange(e)} />
                        <PasswordInput value={password} onChange={(e) => handlePasswordChange(e)} />
                    </section>
                    <section>
                        <Button
                            variant="large"
                            startIcon={<SignIn fill={color} />}
                            sx={{ color, '&:hover': { backgroundColor: colors.white } }}
                            onClick={handleSignIn}
                            data-testid="loginBtn"
                        >
                            Войти
                        </Button>
                        {
                            window.Telegram?.WebApp?.initData &&
                                <Button
                                    variant="large"
                                    startIcon={<SignIn fill={color} />}
                                    sx={{ color, '&:hover': { backgroundColor: colors.white }, marginTop: '10px' }}
                                    onClick={handleRegistration}
                                    data-testid="regBtn"
                                >
                                    Зарегистрироваться
                                </Button>
                        }
                    </section>
                </>
            ) : (
                <>
                    <TypographySection>
                        <TypographyComment>{textData.welcome.comment}</TypographyComment>
                    </TypographySection>
                    <ThreeItemsSection>
                        <>
                            {threeRoundItemsData.map((item) => (
                                <RoundItem key={item}>
                                    <TypographyRoundItem>{item}</TypographyRoundItem>
                                </RoundItem>
                            ))}
                        </>
                    </ThreeItemsSection>
                    <Button
                        variant="large"
                        endIcon={<StartTrackingArrows fill={color} />}
                        onClick={() => checkIsLogged()}
                        sx={{ color, '&:hover': { backgroundColor: colors.white } }}
                        data-testid="startBtn"
                    >
                        Начать
                    </Button>
                    <TypographyFooter1>
                        Готовы начать? Открыть
                        <TypographyFooter2>Центр Поддержки.</TypographyFooter2>
                    </TypographyFooter1>
                </>
            )}
        </Layout>
    );
};

export default WelcomePage;
