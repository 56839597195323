import { atom, selector } from 'recoil';
import { getKssData } from '../../util/kssData';
import getUserDataWithAvatar from '../../util/user';

export const userInfoAtom = atom({
    key: 'userInfoAtom',
    default: {
        user_id: '',
        username: '',
    },
});

export const userInfoSelector = selector({
    key: 'userInfoSelector',
    get: getUserDataWithAvatar,
});

export const userInfoAtomArr = atom({
    key: 'userInfoArr',
    default: userInfoSelector,
});

export const userForAdminPanelAtom = atom({
    key: "userForAdminPanelAtom",
    default: {},
});
// getUserData()

export const userKssTrackerDataForAdminPanelAtom = atom({
    key: "userKssTrackerDataForAdminPanelAtom",
    default: [],
});

export const userValuesForAdminPanelAtom = atom({
    key: "userValuesForAdminPanelAtom",
    default: [],
});

export const userTrackerForAdminPanelAtom = atom({
    key: "userTrackerForAdminPanelAtom",
    default: [],
});

export const userKssDataForAdminPanelAtom = atom({
    key: "userKssDataForAdminPanelAtom",
    default: [],
});

export const userValuesAtom = atom({
    key: 'userValues',
    default: [],
});

export const userSessionTeamAtom = atom({
    key: 'userSessionTeam',
    default: [],
});

export const kssDataSelector = selector({
    key: 'kssDataSelector',
    get: getKssData,
    set: ({ set }, newData) => {
        set(kssDataAtom, newData);
    },
})

export const kssDataAtom = atom({
    key: 'kssData',
    default: kssDataSelector,
})