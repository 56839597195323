import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.css';
import {ThemeProvider} from '@mui/material';
import {theme} from './styles/globalStyles';
import {RecoilRoot} from "recoil";

// window.Telegram.WebApp = {
//     "initData": "query_id=AAHjjZZJAgAAAOONlkblsx9wM&user=%7B%22id%22%3A5529570787%2C%22first_name%22%3A%22Aleksandr%22%2C%22last_name%22%3A%22Bakharev%20l%20evilUnion%22%2C%22username%22%3A%22aleksandr_bakharev_evilunion%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1706790371&hash=9e8131635f35df0ea9617a90181e660b8e673a86d21fd89ebdcf45c7b0768d5a",
//     "initDataUnsafe":
//         {
//             "query_id": "AAHjjZZJAgAAAOONlklsx9wM",
//             "start_param": "Z8VwEK",
//             "user":
//                 {
//                     "id": 5529570989,
//                     "first_name": "Aleksandr",
//                     "last_name": "Bakharev l evilUnion",
//                     "username": "aleksandr_bakharev_evilunion",
//                     "language_code": "en",
//                     "is_premium": true,
//                     "allows_write_to_pm": true
//                 },
//             "auth_date": "1706790371", "hash": "9es131635f35df0ea9617a90181e660b8e673a86d21fd89ebdcf45c7b0768d5a"
//         },
//     "version": "7.0",
//     "platform": "tdesktop",
//     "colorScheme": "dark",
//     "themeParams": {
//         "accent_text_color": "#6ab2f2",
//         "bg_color": "#17212b",
//         "button_color": "#5288c1",
//         "button_text_color": "#ffffff",
//         "destructive_text_color": "#ec3942",
//         "header_bg_color": "#17212b",
//         "hint_color": "#708499",
//         "link_color": "#6ab3f3",
//         "secondary_bg_color": "#232e3c",
//         "section_bg_color": "#17212b",
//         "section_header_text_color": "#6ab3f3",
//         "subtitle_text_color": "#708499",
//         "text_color": "#f5f5f5"
//     },
//     "isExpanded": true,
//     "viewportHeight": 650,
//     "viewportStableHeight": 650,
//     "isClosingConfirmationEnabled": false,
//     "headerColor": "#17212b",
//     "backgroundColor": "#17212b",
//     "BackButton": {"isVisible": false},
//     "MainButton": {
//         "text": "CONTINUE",
//         "color": "#5288c1",
//         "textColor": "#ffffff",
//         "isVisible": false,
//         "isProgressVisible": false,
//         "isActive": true
//     },
//     "SettingsButton": {"isVisible": false},
//     "HapticFeedback": {},
//     "CloudStorage": {}
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RecoilRoot>
                <App/>
            </RecoilRoot>
        </ThemeProvider>
    </React.StrictMode>,
);

window.addEventListener('load', async () => {
    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register('/service-worker.js')
        } catch (e) {
            console.error(e)
        }
    }
})
