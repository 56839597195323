import React from 'react';
import {Typography} from '@mui/material';
import {styled} from '@mui/system';
import {colors} from '../../styles/globalStyles';
import {getColorType} from '../../pages/trackerPage';
import RootModal from "./rootModal";
import RateGrid from "./rateGrid/rateGrid";

const Header = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyItems: "space-between",
    height: "100%",
})

const Question = styled(Typography)({
    color: colors.white,
    fontSize: 28,
    textAlign: "center",
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)"
})

export const Label = styled(Typography)({
    color: "inherit",
    fontSize: 34,
    lineHeight: "34px"
})
const RateModal = ({selectedType, setSelectedData, open, onClose}) => {
    return (
        selectedType && <RootModal open={open} color={getColorType(selectedType?.status)} onClose={onClose}>
            <Header>
                <Question variant={"h1"}>
                    {selectedType["behavior_title"]}
                </Question>
            </Header>
            <RateGrid
                onClose={onClose}
                selectedType={selectedType}
                setSelectedData={setSelectedData}/>
        </RootModal>
    )
}

export default RateModal