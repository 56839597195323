import React, { useState } from 'react';
import { IconButton, styled, Typography } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { ReactComponent as ArrowRight } from '../images/arrow_right.svg';
import {
    Header as ModalHeader,
    HeaderTitle,
    ModalCard,
    ModalCards,
    ModalMainContent,
    ModalTypography,
} from './supportCenterInstruction';
import RootModal from './modals/rootModal';

const Layout = styled('div')({
    padding: 17,

    background: colors.transparentMain,
    color: colors.darkBlue,
    letterSpacing: '0.01em',
    borderRadius: 40,
});

const Header = styled('header')({
    padding: 10,
    marginBottom: 10,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const TypographyHeader = styled(Typography)({
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '100%',
});

const FaqItems = styled('main')({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
});

const FaqItem = styled('div')({
    paddingInline: '17px 10px',
    height: 72,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    background: colors.transparentMain,
    borderRadius: 23,
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: 'rgba(59, 122, 246, 0.2)',
    },
});

const TypographyFaqItem = styled(Typography)({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '128%',
});

const FaqItemButton = styled(IconButton)({
    marginLeft: 30,

    width: 52,
    height: 52,

    background: colors.transparentMain,
    borderRadius: 13,

    '&:hover': {
        backgroundColor: 'rgba(59, 122, 246, 0.3)',
    },
});

const SupportCenterFaq = ({ data }) => {
    window.scroll(0, 0);
    const modalCardsHeight = document.getElementById('modal-cards')?.offsetHeight ?? 222;

    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(data[0]);

    const handleFaqButtonClick = (i) => {
        setModalData(data[i]);
        setOpen(true);
    };

    return (
        <Layout>
            <Header>
                <TypographyHeader>FAQ</TypographyHeader>
            </Header>
            <FaqItems>
                {data.map((item, i) => (
                    <FaqItem key={i} onClick={() => handleFaqButtonClick(i)}>
                        <TypographyFaqItem>{item.titleText}</TypographyFaqItem>
                        <FaqItemButton>
                            <ArrowRight fill={colors.darkBlue} />
                        </FaqItemButton>
                    </FaqItem>
                ))}
            </FaqItems>
            <RootModal
                open={open}
                onClose={() => setOpen(false)}
                breadcrumbsData={[
                    {
                        label: 'Центр Поддержки',
                        uri: '/support',
                    },
                    {
                        label: 'FAQ',
                        uri: '',
                    },
                ]}
                style={{ display: 'block' }}
            >
                <ModalHeader>
                    <HeaderTitle dangerouslySetInnerHTML={{ __html: modalData.title }} />
                </ModalHeader>
                <ModalMainContent sx={{ marginBottom: `${modalCardsHeight + 17}px` }}>
                    <ModalTypography
                        component="div"
                        dangerouslySetInnerHTML={{ __html: modalData.text }}
                    />
                </ModalMainContent>
                <ModalCards id="modal-cards">
                    {data
                        .filter(({ titleText }) => titleText !== modalData.titleText)
                        .sort(() => 0.5 - Math.random())
                        .slice(0, 3)
                        .map((card) => (
                            <ModalCard key={card.titleText} onClick={() => setModalData(card)}>
                                {card.titleText}
                            </ModalCard>
                        ))}
                </ModalCards>
            </RootModal>
        </Layout>
    );
};

export default SupportCenterFaq;
