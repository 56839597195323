import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import TeammateBehaviors from '../components/teammateBehaviors';

const TeammateBehaviorsPage = () => {
    const {userId} = useParams()

    return <TeammateBehaviors userId={userId}/>;
};

export default TeammateBehaviorsPage;
