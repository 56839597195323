import React from 'react';
import { Avatar, Button, styled } from '@mui/material';
import { colors } from '../styles/globalStyles';
import intg101Logo from '../images/intg101_logo_color.svg';
import { ReactComponent as LeftArrow } from '../images/left_arrow.svg';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userInfoAtomArr } from '../recoil/atoms/userAtom';
import ReplayIcon from '@mui/icons-material/Replay';

const Wrap = styled('div')({
    background: colors.white,
    padding: '2px 17px 17px',
    marginBottom: 45,
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
    gap: 17,
    minHeight: '100vh',
    maxWidth: 390,
    margin: "0 auto"
});

const Header = styled('div')({
    position: 'relative',
    paddingInline: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '9.33px'
});

const AvatarWrap = styled('div')({
    position: 'absolute',
    top: 2,
    right: 10,
    background: 'linear-gradient(white,white) padding-box, linear-gradient(red,blue) border-box',
    borderWidth: 2,
    padding: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '100%',
    cursor: 'pointer',
});

const ButtonStyled = styled(Button)({
    position: 'absolute',
    top: 5,
    left: 10
})

const ButtonReload = styled(Button)({
    display: 'flex',
    minWidth: 30,
    width: '30px',
    height: 30,
    padding: 0,
    position: 'absolute',
    top: 5,
    right: 60,
    "& span":{
        margin: 0
    }
})

const Layout = ({ children, link, style, openModal }) => {
    const { avatar } = useRecoilValue(userInfoAtomArr);
    const navigate = useNavigate();

    return (
        <Wrap style={style}>
            <Header style={{ justifyContent: link ? 'center' : 'flex-start'}}>
                {link && (
                    <ButtonStyled
                        variant="coloredLight"
                        sx={{
                            color: colors.darkBlue,
                            '&:hover': { backgroundColor: colors.transparentMain },
                        }}
                        startIcon={<LeftArrow fill={colors.darkBlue} />}
                        onClick={() =>
                            openModal
                                ? navigate(link, { state: { openModal: openModal } })
                                : navigate(link)
                        }
                    >
                        Назад
                    </ButtonStyled>
                )}
                <img src={intg101Logo} alt="intg101 logo" />
                <AvatarWrap onClick={() => navigate('/profile')}>
                    <Avatar src={avatar} sx={{ width: 28, height: 28 }} />
                </AvatarWrap>
                <ButtonReload
                        variant="coloredLight"
                        sx={{
                            color: colors.darkBlue,
                            '&:hover': { backgroundColor: colors.transparentMain },
                        }}
                        startIcon={<ReplayIcon fill={colors.darkBlue} style={{margin: 0}}/>}
                        onClick={() => window.location.reload(true)}
                    >
                    </ButtonReload>
            </Header>
            {children}
        </Wrap>
    );
};

export default Layout;
