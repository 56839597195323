import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import QuizAnswer from "./quizAnswer";
import { useState } from "react";

const Wrapper = styled('div')({
    flex: '0 0 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '50px',
    alignItems: 'center',
    minHeight: '300px',
    width: '100%'
});

const Question = styled(Typography)({
    fontSize: '30px'
})

const AnswersWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    maxHeight: '300px',
    overflowY: "auto",
    width: '100%',
    padding: '0 10px',
});

const QuizComponent = ({ question, onAnswerSelect, isSelected }) => {

    const handleAnswerSelect = (answerId) => {
        onAnswerSelect(answerId);
    };

    const questionAnswerItems = question.options.map(answer => (
        <QuizAnswer
            key={answer.id}
            question={question.question_value}
            answer={answer}
            isSelected={isSelected === answer.id}
            onAnswerSelect={() => handleAnswerSelect(answer.id)}
        />
    ));

    return (
        <Wrapper>
            <Question variant={'h1'}>
                {question.question_value}
            </Question>
            <AnswersWrapper>
                {questionAnswerItems}
            </AnswersWrapper>
        </Wrapper>
    );
};

export default QuizComponent;
