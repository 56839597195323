import React from "react";
import {Button, styled} from "@mui/material";
import Bg from "../../images/bg.png";
import {colors} from "../../styles/globalStyles";
import {ReactComponent as ArrowRight} from '../../images/arrow_right.svg'

const PatternedButtonStyle = styled(Button)({
    padding: "17px 21px 17px 27px",
    width: '100%',
    minHeight: 86,
    justifyContent: 'space-between',
    textTransform: 'none',
    textAlign: "left",

    background: `url(${Bg}), linear-gradient(53.36deg, #3B4EF6 -32.52%, #3BC9F6 69.33%)`,
    color: colors.white,
    borderRadius: 40,

    fontSize: 24,
    fontWeight: 700,
    lineHeight: '110%',
    letterSpacing: "0.01%",
    fontFamily: "Onest",

    '&:hover': {
        background: `url(${Bg}), linear-gradient(53.36deg, #3B4EF6 -32.52%, #3BC9F6 69.33%)`,
        filter: 'brightness(95%)',
    },

    '&:disabled': {
        color: colors.white,
        opacity: 0.5
    }
});

const ArrowWrap = styled('div')({
    width: 52,
    height: 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.white,
    borderRadius: 23,

    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
});

const PatternedButton = ({onClick, label, disabled = false, endIcon}) => (
    <PatternedButtonStyle endIcon={
        endIcon !== null ?
            <ArrowWrap>
                <ArrowRight fill="#3BC9F6"/>
            </ArrowWrap>
            : null
    } onClick={onClick} disabled={disabled}>
        {label}
    </PatternedButtonStyle>
)

export default PatternedButton