import {createTheme, outlinedInputClasses} from '@mui/material';
import './onest.css';

export const bgColors = {
    blue: `linear-gradient(27.72deg, #3B4EF6 23.24%, #3BC9F6 100%)`,
    purpleBlue: `linear-gradient(197.44deg, #24A3FF 20.58%, #9F24FF 65.25%, #CA3E9A 96.37%)`,
    orangePink: `linear-gradient(142.62deg, #EFB94F 19.22%, #EF4F88 63.46%, #CB3BAB 86.23%)`,
    green: `linear-gradient(200.32deg, #FFD952 0%, #11D1A3 43.43%, #1198D1 92.71%)`,
};

export const colors = {
    darkBlue: '#1E304A',
    main: '#3B7AF6',
    white: '#FFFFFF',
    purple: '#6C59FF',
    pink: '#EF6E77',
    green: '#10E70C',
    lightGreen: '#12CDA7',
    red: '#D23E3E',
    yellow: '#F6B63B',
    gray: "#dddddd",
    transparentWhite: 'rgba(255, 255, 255, 0.14)',
    transparentRed: 'rgba(255, 122, 122, 0.14)',
    lessTransparentRed: 'rgba(255, 122, 122, 0.5)',
    transparentRedHover: 'rgba(255, 122, 122, 0.2)',
    transparentMain: 'rgba(59, 122, 246, 0.14)',
    gradient: {
        bg: {
            keep: 'linear-gradient(180deg, #24A3FF 0%, #A025FC 100%)',
            stop: 'linear-gradient(180deg, #EFB94F 0%, #E44993 100%)',
            start: 'linear-gradient(180deg, #F0D857 0%, #12C6AC 100%)',
            gray: 'linear-gradient(180deg, #DADADA 0%, #999999 100%)',
        },
        line: {
            keep: '#5617A6',
            stop: '#930D75',
            start: '#107F8E',
            sessionProgress: {
                positive: 'linear-gradient(339.22deg, #1C9FC8 13.67%, #35D789 86.17%)',
                negative: 'linear-gradient(167.89deg, #E19259 8.89%, #E76161 91.22%)',
            },
        },
    },
};

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 415, // phone
            sm: 700, // tablet
            md: 900, // small laptop
            lg: 1024, // desktop
            xl: 1536, // large screens
        },
    },
    palette: {
        keep: colors.purple,
        stop: colors.red,
        start: colors.lightGreen,
        main: colors.main,
        darkBlue: colors.darkBlue,
    },
    typography: {
        fontFamily: ['Onest', 'sans-serif'].join(','),
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 700,
        fontWeightBold: 800,
        h1: {
            fontWeight: 800,
        },
        h2: {
            fontWeight: 700,
        },
        h3: {
            fontWeight: 500,
        },
        h4: {
            fontWeight: 400,
        },
        allVariants: {
            color: colors.darkBlue,
        },
    },
    components: {
        MuiTextField: {
          styleOverrides:{
              root:{
                  ".MuiOutlinedInput-notchedOutline":{
                      borderRadius: 23,
                      border: "2px solid rgba(59, 122, 246, 0.24)",
                  },

                  ".MuiOutlinedInput-notchedOutline:hover":{
                      borderRadius: 23,
                      border: "2px solid rgba(59, 122, 246, 0.24)",
                  }
              }
          }
        },
        MuiCard: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.transparentMain,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                    borderRadius: 10,
                    fontWeight: 500,
                    lineHeight: '18px',
                    '&:hover': {
                        backgroundColor: colors.main,
                        backgroundImage: 'linear-gradient(rgb(0 0 0/10%) 0 0)',
                    },
                },
            },
            defaultProps: {
                disableElevation: true,
            },
            variants: [
                {
                    props: { variant: 'colored' },
                    style: {
                        backgroundColor: colors.main,
                        color: colors.white,
                    },
                },
                {
                    props: { variant: 'coloredLight' },
                    style: {
                        backgroundColor: colors.transparentMain,
                        color: colors.darkBlue,
                        '&:hover': {
                            backgroundColor: colors.transparentMain,
                        },
                    },
                },
                {
                    props: { variant: 'coloredLightRed' },
                    style: {
                        backgroundColor: colors.transparentRed,
                        color: colors.red,
                        '&:hover': {
                            backgroundColor: colors.transparentRed,
                        },
                    },
                },
                {
                    props: { variant: 'coloredRed' },
                    style: {
                        backgroundColor: colors.lessTransparentRed,
                        color: colors.red,
                        '&:hover': {
                            backgroundColor: colors.lessTransparentRed,
                        },
                    },
                },
                {
                    props: { variant: 'transparentLight' },
                    style: {
                        backgroundColor: colors.transparentWhite,
                        color: colors.white,
                        '&:hover': {
                            backgroundColor: colors.transparentMain,
                        },
                    },
                },
                {
                    props: { variant: 'light' },
                    style: {
                        backgroundColor: colors.white,
                        '&:hover': {
                            backgroundColor: colors.white,
                        },
                    },
                },
                {
                    props: { variant: 'large' },
                    style: {
                        backgroundColor: colors.white,
                        borderRadius: 40,
                        height: 89,
                        width: '100%',
                        fontWeight: 700,
                        fontSize: 20,
                        lineHeight: '100%',
                        letterSpacing: '0.01em',
                    },
                },
                {
                    props: { variant: 'dropdownBlue' },
                    style: {
                        paddingInline: '12px 8px',
                        height: 26,
                        maxWidth: 'fit-content',
                        justifyContent: 'space-between',
                        backgroundColor: colors.main,
                        borderRadius: 23,
                        '& .MuiTypography-root': {
                            color: colors.white,
                        },
                        '& span': {
                            marginLeft: 12,
                            marginRight: 0,
                        },
                    },
                },
                {
                    props: { variant: 'dropdownWhite' },
                    style: {
                        paddingInline: '12px 8px',
                        height: 26,
                        maxWidth: 'fit-content',
                        justifyContent: 'space-between',
                        backgroundColor: colors.white,
                        borderRadius: 23,
                        '& .MuiTypography-root': {
                            color: colors.main,
                        },
                        '& span': {
                            marginLeft: 12,
                            marginRight: 0,
                        },
                        '& span svg': {
                            fill: colors.main,
                        },
                        '&:hover': {
                            backgroundColor: colors.white,
                        },
                    },
                },
                {
                    props: { variant: 'dropdownLarge' },
                    style: {
                        paddingInline: 16,
                        paddingBlock: 11,
                        height: 42,
                        maxWidth: 'fit-content',
                        justifyContent: 'space-between',
                        backgroundColor: colors.transparentMain,
                        borderRadius: 23,
                        '& .MuiTypography-root': {
                            fontSize: 16,
                            color: colors.main,
                        },
                        '& span': {
                            marginLeft: 12,
                            marginRight: 0,
                        },
                        '& span svg': {
                            fill: colors.main,
                        },
                        '&:hover': {
                            backgroundColor: colors.transparentMain,
                        },
                    },
                },
            ],
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '150%',
                    letterSpacing: '0.01em',
                },
                sizeSmall: {
                    paddingBlock: 4,
                    height: 20,
                    maxWidth: 'fit-content',
                },
            },
            variants: [
                {
                    props: { variant: 'lightBlue' },
                    style: {
                        background: colors.transparentMain,
                        color: colors.main,
                    },
                },
                {
                    props: { variant: 'transparentWhite' },
                    style: {
                        background: colors.transparentWhite,
                        color: colors.white,
                    },
                },
            ],
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    fontFamily: ['Onest', 'sans-serif'].join(','),
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: '12px',
                    letterSpacing: '0.01em',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    background: colors.transparentWhite,
                },
            },
        },
    },
});
