import React from "react";
import LayoutSkeleton from "./layoutSkeleton";
import {styled} from "@mui/system";
import {Skeleton} from "@mui/material";

const Container = styled("div")({
    marginTop: 17,
    display: "flex",
    flexDirection: "column",
    gap: 17,
})
const MainPanelSkeleton = ()=>{

    return(
        <LayoutSkeleton>
            <Container>
                <Skeleton variant={"rectangular"} sx={{width: "100%", height: 20, borderRadius:23}}/>
                <Skeleton variant={"rectangular"} sx={{width: "245px", height: "34px", marginLeft: "10px"}}/>
                <Skeleton variant={"rectangular"} sx={{width: "100%", height: 261, borderRadius: "40px"}}/>
                <Skeleton variant={"rectangular"} sx={{width: "100%", height: 322, borderRadius: "40px"}}/>
                <div style={{display: "flex", height:"262.8px", gap: 2}}>
                    <Skeleton variant={"rectangular"} sx={{width: "100%", height: "100%", borderRadius: "40px"}}/>
                    <Skeleton variant={"rectangular"} sx={{width: "100%", height: "100%", borderRadius: "40px"}}/>
                </div>
                <Skeleton variant={"rectangular"} sx={{width: "100%", height: "86px", borderRadius: 40}}/>
            </Container>
        </LayoutSkeleton>
    )
}

export default MainPanelSkeleton