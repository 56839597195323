import React from 'react';
import { styled } from '@mui/material';
import { colors } from '../../styles/globalStyles';

export const InputWrap = styled('div')({
    borderRadius: 40,
    background: colors.transparentWhite,
    padding: 17,
    marginBottom: 2,
    display: 'flex',
    gap: 10,
    alignItems: 'center',

    "input::placeholder":{
        color: "rgba(255,255,255,0.5)"
    }
});

export const inputBaseStyles = {
    background: "transparent",
    width: '100%',
    fontFamily: 'Onest,sans-serif',
    fontWeight: 400,
    fontSize: 16,
    outline: "none",
    border: "none",
    lineHeight: "20px",
    letterSpacing: '0.01em',
    color: colors.white,
};
