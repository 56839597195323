import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { colors } from "../styles/globalStyles";

const Wrapper = styled('div')({
    width: '100%'
});

const QuizAnswerButton = styled(Button)(({ selected }) => ({
    borderRadius: 23,
    background: selected ? colors.main : colors.transparentMain,
    color: selected ? colors.white : colors.main,
    width: '100%',
    fontSize: '20px',
    padding: '18px 8px',
    '&:hover': {
        backgroundImage: 'none',
        background: selected ? colors.main : colors.transparentMain,
        color: selected ? colors.white : colors.main,
    }
}));

const QuizAnswer = ({ question, answer, isSelected, onAnswerSelect }) => {
    return (
        <Wrapper>
            <label>
                <input
                    name={question}
                    value={answer.answer_value}
                    checked={isSelected}
                    type="radio"
                    style={{ display: 'none' }}
                    onChange={onAnswerSelect}
                />
                <QuizAnswerButton selected={isSelected} onClick={onAnswerSelect}>
                    {answer.answer_value}
                </QuizAnswerButton>
            </label>
        </Wrapper>
    );
};

export default QuizAnswer;
