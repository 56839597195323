import React from 'react';
import {Skeleton, styled, Typography} from '@mui/material';
import { colors } from '../styles/globalStyles';
import { useRecoilValue } from 'recoil';
import { weekProgressDataAtom } from '../recoil/atoms/mySessionPageAtoms';
import { getBarHeight } from '../util/render';

const Layout = styled('div')({
    width: '50%',
    flexShrink: 0,
    height: "100%",

    display: 'flex',
    flexDirection: 'column',

    background: colors.transparentMain,
    borderRadius: 40,
    overflow: 'hidden',
});

const Header = styled('header')({
    padding: 27,
    paddingBottom: 10,
    color: colors.main,
});

export const Progress = styled(Typography)({
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '125%',
    opacity: 0.6,
    color: colors.main,
});

const Percentage = styled(Typography)({
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '128%',
    color: colors.main,
});

export const Plot = styled('main')({
    height: '100%',
    display: 'flex',
    alignItems: 'end',
    gap: 2,
});

export const PlotBar = styled('div')({
    content: '""',
    width: '100%',
    borderRadius: '17px 17px 0 0',
    transition: 'height 1s',
});

const MySessionsWeekProgress = () => {
    const data = useRecoilValue(weekProgressDataAtom);
    return (
        data ? <Layout>
                <Header>
                    <Progress>Прогресс по KSS за неделю:</Progress>
                    <Percentage>{data.progress}%</Percentage>
                </Header>
                <Plot>
                    <PlotBar sx={{ height: getBarHeight(data.keep, data["max_score"], 2), background: colors.gradient.bg.keep }}/>
                    <PlotBar sx={{ height: getBarHeight(data.stop, data["max_score"], 2), background: colors.gradient.bg.stop }}/>
                    <PlotBar sx={{ height: getBarHeight(data.start, data["max_score"], 2), background: colors.gradient.bg.start }}/>
                </Plot>
            </Layout>
        :
                <Skeleton variant={"rectangular"} sx={{height: "100%", width: "50%", borderRadius: "40px", flexShrink: 0}}/>

    );
};

export default MySessionsWeekProgress;
