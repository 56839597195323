import {Button, Modal, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {styled} from "@mui/system";
import cross from "./../../images/cross.svg";

const Wrapper = styled(Button)({
    background: 'linear-gradient(white, white) padding-box, linear-gradient(red, blue) border-box',
    borderWidth: '2px',
    padding: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '100%',
    cursor: 'pointer',
    minWidth: '64px',

    "&:hover": {
        background: 'linear-gradient(white, white) padding-box, linear-gradient(red, blue) border-box',
    }
})

const StoryPreview = styled('img')({
    aspectRatio: "1/1",
    width: "64px",
    objectFit: 'cover',
    borderRadius: '100%',
    overflow: 'hidden',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
});

const StoryWrapper = styled('div')({
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    position: "relative"
})

const CloseButton = styled(Button)({
    minWidth: '48px',
    width: '48px',
    height: '48px',
    borderRadius: '100%',
    background: 'rgba(256, 256, 256, 0.5)',
    position: 'absolute',
    top: '20px',
    right: '20px',
    zIndex: 2
});

const CloseIcon = styled('img')({
    width: "70%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const Story = styled('img')({
    width: "100%",
    height: "100%",
    objectFit: "cover"
});

const StoryTitleWrapper = styled('div')({
    overflowY: 'auto',
    position: "absolute",
    top: '20px',
    // left: 0,
    // right: 0,
    width: 'calc(100% - 68px)',
    padding: '0 20px',
    zIndex: 2
});

const StoriesTopShadow = styled('div')({
    background: 'linear-gradient(0deg, rgba(0,0,0,0) 8%, rgba(0,0,0,0.3) 87%)',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: '100px'
})

const StoriesBreadcrumbs = styled('div')({
    position: "absolute",
    top: '10px',
    left: 0,
    right: 0,
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    gap: "3px",
    zIndex: 2
});

const Breadcrumb = styled('div')({
    height: "2px",
    background: "#FFFFFF",
    width: '100%',
    "&.active": {
        background: "#000000",
    },
})

const PrevButton = styled('div')({
    position: "absolute",
    width: '50%',
    top: 0,
    bottom: 0,
    zIndex: 1,
});

const NextButton = styled('div')({
    position: "absolute",
    width: '50%',
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
})

const StoryModal = ({storiesGroup, allStoriesList}) => {
    const [open, setOpen] = useState(false);
    const [currentStoriesGroup, setCurrentStoriesGroup] = useState(storiesGroup);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

    const breadcrumbsItems = currentStoriesGroup?.slides?.map(story => <Breadcrumb key={story.id}
                                                                                   className={story.id === currentStoriesGroup?.slides[currentStoryIndex]?.id ? "active" : ""}/>);

    const handleOpenStory = () => {
        setCurrentStoriesGroup(storiesGroup);
        setCurrentStoryIndex(0);
        setOpen(true);
    }

    const handleClickPrevButton = () => {
        if (currentStoryIndex === 0) {
            const currentStoriesGroupIndex = allStoriesList.findIndex(group => group.id === currentStoriesGroup.id);
            const prevStoriesGroup = currentStoriesGroupIndex !== 0 ? allStoriesList[currentStoriesGroupIndex - 1] : undefined;

            if (prevStoriesGroup) {
                setCurrentStoriesGroup(prevStoriesGroup);
                setCurrentStoryIndex(prevStoriesGroup?.slides?.length - 1);
            } else {
                return;
            }

            return;
        }
        setCurrentStoryIndex(currentStoryIndex - 1);
    };

    const handleClickNextButton = () => {
        if (currentStoryIndex === currentStoriesGroup?.slides?.length - 1) {
            const currentStoriesGroupIndex = allStoriesList.findIndex(group => group.id === currentStoriesGroup.id);
            const nextStoriesGroup = allStoriesList[currentStoriesGroupIndex + 1];

            if (nextStoriesGroup) {
                setCurrentStoriesGroup(nextStoriesGroup);
                setCurrentStoryIndex(0);
            } else {
                setOpen(false);
            }

            return;
        }
        setCurrentStoryIndex(currentStoryIndex + 1);
    }

    return (
        <>
            <Wrapper>
                <StoryPreview
                    src={storiesGroup.image}
                    alt={storiesGroup.title}
                    onClick={handleOpenStory}
                />
            </Wrapper>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
            >
                <StoryWrapper>
                    <StoriesTopShadow/>
                    <StoriesBreadcrumbs>
                        {breadcrumbsItems}
                    </StoriesBreadcrumbs>
                    <CloseButton onClick={() => setOpen(false)}>
                        <CloseIcon src={cross} alt={"Закрыть"}/>
                    </CloseButton>
                    <Story src={currentStoriesGroup?.slides[currentStoryIndex]?.image}/>
                    <StoryTitleWrapper>
                        <Typography as={"p"} variant={"body1"} sx={{color: "#FFFFFF", fontSize: '14px'}}>
                            {currentStoriesGroup?.slides[currentStoryIndex]?.title}
                        </Typography>
                    </StoryTitleWrapper>
                    <PrevButton onClick={handleClickPrevButton}/>
                    <NextButton onClick={handleClickNextButton}/>
                </StoryWrapper>
            </Modal>
        </>
    );
};

export default StoryModal;