import { Button, Card, IconButton, Typography } from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../images/info_icon.svg";
import { ReactComponent as ArrowRight } from "../../../images/arrow_right.svg";
import React from "react";
import { getColorType } from "../../../pages/trackerPage";
import { styled } from "@mui/system";
import BgImage from "../../../images/bg.png";
import { colors } from "../../../styles/globalStyles";
import { getCaption } from "../../../util/scores";

const StyledCard = styled(Card)(({ backgroundcolor }) => ({
    background: `${backgroundcolor}, url(${BgImage})`,
    backgroundSize: 'cover',
    backgroundBlendMode: 'multiply',
    color: colors.white,
    width: "95%",
    height: "100%",
    minHeight: 320,
    borderRadius: "40px 40px 0 0 ",
    boxShadow: "unset",
    marginBottom: 0,
    marginTop: "auto",
    flexShrink: 0,
    scrollSnapAlign: "center",
    padding: 17,
    display: "flex",
    flexDirection: "column",
    "@media(min-width:1440px)": {
        maxWidth: "23vw",
    },
    '&:first-of-type': {
        marginLeft: '17px',
    },
    '&:last-of-type': {
        marginRight: '17px',
    },
}))

const Header = styled("div")({
    display: "flex",
    justifyContent: "flex-end"
})

const Case = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
})

const Value = styled("div")({
    display: "flex",
    alignItems: "center",
    gap: 5
})

const ValueText = styled(Typography)({
    fontWeight: 400,
    color: colors.white,
    lineHeight: "18px",
    fontSize: 14,
})

const Behavior = styled(Typography)({
    color: colors.white,
    fontWeight: 800,
    fontSize: 28,
    textAlign: "center",
    marginBottom: 10,
})

const RateButton = styled(Button)({
    background: colors.transparentWhite,
    color: colors.white,
    height: 81,
    borderRadius: 23,
    fontWeight: 400,
    flexShrink: 0,

    marginTop: 'auto',

    '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)'
    }
})

const RateValue = styled("div")({
    display: "flex",
    flexDirection: "column",

    "& .rate-value": {
        fontWeight: 800,
        fontSize: 32,
        color: colors.white
    },

    "& .caption": {
        paddingTop: 4,
        fontWeight: 500,
        color: colors.white
    }
})

const SliderCard = ({ style, kssTrackerCardData, setOpen, reference, setSelected, info = true, setOpenInfo, canScore = true, scores }) => {
    const getFont = (text) => {
        if (!text) return
        if (text.length < 65) return 28
        if (text.length < 80) return 24
        if (text.length < 120) return 20
        if (text.length < 160) return 18
        if (text.length < 200) return 16
        return 14
    }

    if (!kssTrackerCardData || kssTrackerCardData.skip) {
        return null;
    }

    return (
        <StyledCard ref={reference} backgroundcolor={getColorType(kssTrackerCardData.status)} style={style}>
            {
                info ? (
                    <Header>
                        <IconButton sx={{ width: 36, height: 36 }} onClick={() => {
                            setOpenInfo(true)
                            setSelected(kssTrackerCardData)
                        }}>
                            <InfoIcon />
                        </IconButton>
                    </Header>
                ) : null
            }

            <Case>
                <Value>
                    <svg width={10} height={10} viewBox="0 0 10 10" fill={kssTrackerCardData.status === "stop" ? colors.red : colors.green}
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="5" />
                    </svg>
                    <ValueText>
                        {kssTrackerCardData.value_name}
                    </ValueText>
                </Value>
                <Behavior
                    style={{ fontSize: getFont(kssTrackerCardData.title) }}
                >
                    {kssTrackerCardData.title}
                </Behavior>
            </Case>
            
            {!scores ? (
                canScore ? (
                    <RateButton endIcon={<ArrowRight />} onClick={() => {
                        setSelected(kssTrackerCardData)
                        if (setOpen) setOpen(true)
                    }}>
                        Оценить
                    </RateButton>
                ) : (null)
            ) : scores?.result && (
                <RateButton
                    disabled={!canScore}
                    style={!canScore ? { background: 'none' } : null}
                    onClick={() => {
                        setSelected(kssTrackerCardData)
                        if (setOpen) setOpen(true)
                    }}
                >
                    <RateValue>
                        <Typography className={"rate-value"} style={{ marginBottom: "-10px" }}>
                            {scores.result}
                        </Typography>
                        <div className="caption">
                            {getCaption(scores.result, kssTrackerCardData.max_score)}
                        </div>
                    </RateValue>
                </RateButton>
            )}
        </StyledCard>
    )
}

export default SliderCard