import React, {useEffect, useState} from 'react';
import { Button, Chip, Menu, MenuItem, styled, Typography } from '@mui/material';
import { colors } from '../../styles/globalStyles';
import { ReactComponent as ArrowDown } from '../../images/arrow_down.svg';

const MyMenu = styled(Menu)({
    marginTop: 6,

    '& div.MuiPaper-root': {
        width: 200,
        height: 141,

        border: '1.5px solid #3B7AF6',
        borderRadius: 20,
        boxShadow: 'none',
    },

    // Chrome,Safari,Edge scrollbar styles
    '& ::-webkit-scrollbar': {
        width: 17,
    },

    '& ::-webkit-scrollbar-thumb': {
        borderRadius: 100,
        border: '7px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: colors.transparentMain,
    },

    '& ::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
        borderRadius: 100,
        margin: 10,
    },

    // Firefox scrollbar styles
    '& ul': {
        padding: 0,
    },
});

const TypographyDropdown = styled(Typography)({
    fontSize: 10,
    fontWeight: 700,
});

const MyMenuItem = styled(MenuItem)({
    padding: 17,
    justifyContent: 'space-between',
});

const TypographyMenuItem = styled(Typography)({
    fontWeight: 700,
    fontSize: 10,
    lineHeight: '100%',
    color: colors.darkBlue,
});

const Dates = styled(Chip)({
    fontSize: 10,
    borderRadius: 6,
});

const MenuItemBottomBorder = styled('div')({
    margin: '0 auto',
    maxWidth: '83%',
    borderBottom: '1px solid rgba(59, 122, 246, 0.14)',
});

const Dropdown = ({ options, variant, width, height, font, setSelectedOption, selected=0}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(selected);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        setSelectedIndex(selected);
    }, [selected])

    const handleDropdownButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (setSelectedOption)
            setSelectedOption(options[selectedIndex])
    }, [selectedIndex]);


    return (
        <>
            <Button
                component="nav"
                variant={variant || 'dropdownBlue'}
                endIcon={<ArrowDown />}
                onClick={handleDropdownButtonClick}
                sx={{ minWidth: width, height }}
            >
                <TypographyDropdown style={font ? {fontSize: font}: null}>{options[selectedIndex].name}</TypographyDropdown>
            </Button>
            <MyMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options.map(({name, interval }, index) => (
                    <div key={name}>
                        <MyMenuItem
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                        >
                            <TypographyMenuItem style={font ? {fontSize: font}: null}>{name}</TypographyMenuItem>
                            {interval && <Dates label={interval} size="small" variant="lightBlue" />}
                        </MyMenuItem>
                        <MenuItemBottomBorder />
                    </div>
                ))}
            </MyMenu>
        </>
    );
};

export default Dropdown;
