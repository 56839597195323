import {axiosInstance} from "./axiosInstance";
import axios from "axios";
import {SERVER_URI_API} from "./urls";

export const getQuizData = () => axios.get(`${SERVER_URI_API}/survey/`);

export const postQuiz = (quizData) => axios.post(`${SERVER_URI_API}/survey/results/`, quizData,
    {headers: {
            'Content-Type': 'application/json',
        }}
);

export const postChosenBehaviors = (behaviorsData) => axiosInstance.post(`survey/chosen_behaviors/`, behaviorsData);