import React, {useEffect, useState} from "react";
import {styled} from "@mui/system";
import QuizComponent from "../components/quizComponent";
import {Button, Typography} from "@mui/material";
import {colors} from "../styles/globalStyles";
import {Label} from "../components/modals/rateGrid/rateGrid";
import leftArrow from "./../images/left_arrow.svg";
import {getQuizData, postChosenBehaviors, postQuiz} from "../api/quiz";
import RootModal from "../components/modals/rootModal";
import QuizPersonalData from "../components/QuizPersonalData";
import ResultsValue from "../components/resultsValue";
import {useNavigate} from "react-router-dom";
import {getUserData} from "../api/auth";
import {useSetRecoilState} from "recoil";
import {userInfoAtomArr} from "../recoil/atoms/userAtom";

const PageWrapper = styled('div')({
    background: colors.white,
    padding: '2px 17px 17px',
    marginBottom: 45,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '0 1 auto',
    gap: 17,
    minHeight: '100vh',
    maxWidth: 390,
    margin: "0 auto"
});

const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    transition: 'transform 0.5s ease',
    width: '100%',
    overflow: 'hidden'
});

const ContentWrapper = styled('div')(({currentIndex, quizData}) => ({
    display: 'flex',
    transform: `translateX(-${currentIndex * 100}%)`,
    transition: 'transform 0.5s ease-in-out',
    width: "100%"
}));

const ButtonsGroup = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    padding: '0 10px'
});

const PrevButton = styled(Button)({
    background: colors.darkBlue,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '18px 8px',
    'img': {
        width: '54px',
        height: '34px'
    },

    '&:disabled': {
        opacity: 0.5
    },
    "&:active": {
        background: colors.darkBlue,
    },
    "&:hover": {
        background: colors.darkBlue,
    }
});

const SaveButton = styled(Button)({
    background: colors.transparentWhite,
    borderRadius: 23,
    color: "white",
    padding: '18px 8px',
    flex: 1,

    "&.light": {
        background: colors.main,
        color: colors.white
    },

    '&:disabled': {
        opacity: 0.5,
    }
});

const ResultsOuterWrapper = styled('div')({
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
    gap: "40px",
});

const ResultsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '0 17px',
    flex: 1
});

const SaveButtonWrapper = styled('div')({
    padding: '0 17px 17px'
});

const ResultsTitle = styled(Typography)({
    lineHeight: 1,
    letterSpacing: "0.01rem",
    fontWeight: 800,
    fontSize: 34,
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'capitalize'
});

const QuizPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    let navigate = useNavigate();
    const setUserInfo = useSetRecoilState(userInfoAtomArr);

    const [quizData, setQuizData] = useState([]);
    const [name, setName] = useState(window.Telegram?.WebApp?.initDataUnsafe?.user?.first_name ? window.Telegram?.WebApp?.initDataUnsafe?.user?.first_name : '');
    const [surname, setSurname] = useState(window.Telegram?.WebApp?.initDataUnsafe?.user?.last_name ? window.Telegram?.WebApp?.initDataUnsafe?.user?.last_name : '');

    const [values, setValues] = useState([]);

    useEffect(() => {
        getQuizData()
            .then((response) => {
                setQuizData(response.data);
            })
            .catch((err) => {
                console.error('Ошибка получения вопросов', err);
            });
    }, []);

    const totalQuestions = quizData.length;

    const handlePrevQuestion = () => {
        setCurrentIndex(currentIndex - 1);
    }

    const handleNextQuestion = () => {
        if (currentIndex < totalQuestions) {
            setCurrentIndex(currentIndex + 1);
        } else {
            const quizData = {
                team_hash: window?.Telegram?.WebApp?.initDataUnsafe?.start_param,
                survey: selectedAnswers,
                user: {
                    name: name,
                    surname: surname,
                    telegram_id: window.Telegram?.WebApp?.initDataUnsafe?.user?.id
                }
            }

            postQuiz(quizData)
                .then((res) => {
                    localStorage.setItem('auth-token', res.data.tokens.access);
                    localStorage.setItem('refresh-token', res.data.tokens.refresh);
                    setIsModalOpen(true);
                    setValues(res.data.value_list);
                })
                .catch((err) => {
                    console.error('Ошибка отправки результатов опроса', err);
                });
        }
    };

    const handleAnswerSelect = (questionId, answerId) => {
        setSelectedAnswers(prev => ({
            ...prev,
            [questionId]: answerId
        }));
    };

    const quizItems = quizData.map((question, index) => (
        <QuizComponent
            key={question.id}
            question={question}
            onAnswerSelect={(answerId) => handleAnswerSelect(question.id, answerId)}
            isSelected={selectedAnswers[question.id]}
        />
    ));

    const isAnswerSelected = selectedAnswers[quizData[currentIndex - 1]?.id] !== undefined;

    const [behaviorsData, setBehaviorsData] = useState({});

    useEffect(() => {
        setBehaviorsData(values.flatMap(item => item.behavior).reduce((acc, {id}) => ({...acc, [id]: false}), {}));
    }, [values]);

    const handleSelectedBehaviorChange = (behaviorId) => {
        setBehaviorsData(prev => ({
            ...prev,
            [behaviorId]: !behaviorsData[behaviorId]
        }))
    }

    const valuesItems = values ? values.map(value => <ResultsValue behaviorsData={behaviorsData}
                                                                   handleChange={handleSelectedBehaviorChange}
                                                                   key={value.id} value={value}/>) : [];

    const handleSubmitBehaviors = () => {
        const data = {
            behaviors: {...behaviorsData}
        };

        postChosenBehaviors(data)
            .then((data) => {
                getUserData()
                    .then((res) => {
                        var newInfo = {
                            username: res.data.username,
                            firstName: res.data.first_name,
                            userId: res.data.id,
                            avatar: '',
                            teamName: res.data.team.name,
                            teamId: res.data.team.id,
                        };

                        setUserInfo(newInfo);
                        localStorage.setItem('user-id', res.data.id);
                        localStorage.setItem('team-id', res.data.team.id);
                        localStorage.setItem('username', res.data.username);

                        navigate('/main');
                    })
            })
            .catch((err) => {
                console.error('Ошибка отправки выбранных поведений', err);
            });
    }

    return (
        <>
            <PageWrapper>
                <div></div>
                <Wrapper>
                    <ContentWrapper currentIndex={currentIndex} quizData={quizData}>
                        <QuizPersonalData name={name} surname={surname}
                                          onChangeName={(event) => setName(event.target.value)}
                                          onChangeSurname={(event) => setSurname(event.target.value)}/>
                        {quizItems}
                    </ContentWrapper>
                </Wrapper>
                <ButtonsGroup>
                    <PrevButton disabled={!currentIndex} onClick={handlePrevQuestion}>
                        <img src={leftArrow} alt="Назад"/>
                    </PrevButton>
                    <SaveButton
                        className={"light"}
                        id={"save-button"}
                        name={"save-button"}
                        onClick={handleNextQuestion}
                        disabled={(currentIndex !== 0 && !isAnswerSelected) || (!(name.trim()) || !(surname.trim()))}
                    >
                        <Label style={{fontSize: 20}}>
                            {currentIndex < totalQuestions ? "Продолжить" : "Сохранить"}
                        </Label>
                    </SaveButton>
                </ButtonsGroup>
            </PageWrapper>

            <RootModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                style={{justifyContent: 'flex-start'}}
            >
                <ResultsOuterWrapper>
                    <ResultsWrapper>
                        <ResultsTitle>
                            Выберите откликающиеся вам поведения
                        </ResultsTitle>
                        {valuesItems}
                    </ResultsWrapper>
                    <SaveButtonWrapper onClick={handleSubmitBehaviors}>
                        <SaveButton sx={{width: '100%'}} className={"light"}>Сохранить</SaveButton>
                    </SaveButtonWrapper>
                </ResultsOuterWrapper>
            </RootModal>
        </>
    );
};

export default QuizPage;
