import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useParams } from 'react-router-dom';
import { Button, styled, Typography } from '@mui/material';
import { createClientLog, getMaxScore, setMaxScore } from '../api/adminPanel';
import Dropdown from '../components/dropdowns/dropdown';
import { colors } from '../styles/globalStyles';

const Title = styled(Typography)({
    fontSize: 34,
    lineHeight: '40.8px',
});
const Wrap = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
});

const SaveButton = styled(Button)({
    width: '50%',
    padding: '14px 17.5px',
    borderRadius: 23,
    alignSelf: 'center',
});

const InputWrap = styled('div')({
    display: 'flex',
    gap: 10,
});

const MaxScoreEditPage = () => {
    const { teamId, companyId } = useParams();

    const [maxScoreValue, setMaxScoreValue] = useState({ name: 4 });
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('CHANGED');
    const [minimum, setMinimum] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0)
    const dropDownArr = [{ name: 4 }, { name: 5 }];

    useEffect(() => {
        getMaxScore(teamId)
            .then((r) => {
                setMaxScoreValue({ name: r.data['max_score'] });
                setSelectedIndex(dropDownArr.findIndex(item => item.name === r.data['max_score']))
            })
            .catch((err) => {
                console.error("Ошибка получения максимальной оценки", err);
            });;
    }, []);

    useEffect(() => {
        setStatus('CHANGED');
        if(maxScoreValue.name === 4){
            setMinimum(0)
        } else {
            setMinimum(1)
        }
    }, [maxScoreValue]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setMaxScore({
            teamId: teamId,
            maxScoreValue: maxScoreValue.name,
        })
            .then((r) => {
                setStatus('SUCCESS');
                setLoading(false);
            })
            .catch((e) => {
                createClientLog(e);
                setStatus('ERROR');
            });
    };

    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            <Wrap>
                <Title variant={'h1'}>Шкала оценки</Title>
                <Typography sx={{ opacity: 0.5 }}>Минимум - {minimum}, максимум:</Typography>
                <InputWrap>
                    <Dropdown
                        options={dropDownArr}
                        setSelectedOption={setMaxScoreValue}
                        height={46}
                        width={'50%'}
                        font={16}
                        selected={selectedIndex}
                    />
                    <SaveButton
                        variant={'colored'}
                        onClick={(e) => handleSubmit(e)}
                        disabled={loading}
                    >
                        Сохранить
                    </SaveButton>
                </InputWrap>
                {status === 'ERROR' ? (
                    <Typography sx={{ color: colors.red }}>Ошибка сети</Typography>
                ) : null}
                {status === 'SUCCESS' ? (
                    <Typography sx={{ color: colors.green }}>Оценка успешно изменена</Typography>
                ) : null}
            </Wrap>
        </Layout>
    );
};

export default MaxScoreEditPage;
