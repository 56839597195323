import React, { useState } from 'react';
import { Icon, IconButton, InputBase, styled } from '@mui/material';
import { ReactComponent as EyeSlashIcon } from '../../images/eye_slash_icon.svg';
import { ReactComponent as EyeIcon } from '../../images/eye.svg';
import { colors } from '../../styles/globalStyles';
import { inputBaseStyles, InputWrap } from './decoratedInput';

const StyledIconButton = styled(IconButton)({
    backgroundColor: colors.transparentWhite,
    borderRadius: 23,
    width: 55.5,
    height: 55.5,
    padding: 17.5,
});

const StyledIcon = styled(Icon)({
    display: 'flex',
    alignItems: 'center',
});

const PasswordInput = ({ value, onChange }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <InputWrap>
            <StyledIconButton>
                <StyledIcon onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                </StyledIcon>
            </StyledIconButton>
            <input
                data-testid="passInput"
                type={'text'}
                placeholder={'********'}
                type={showPassword ? 'text' : 'password'}
                style={inputBaseStyles}
                value={value}
                onChange={onChange}
            />
        </InputWrap>
    );
};

export default PasswordInput;
