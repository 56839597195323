import React from 'react';
import {colors} from '../styles/globalStyles';
import {Chip, Skeleton, styled, Typography} from '@mui/material';
import {ReactComponent as ColoredDot} from '../images/colored_dot.svg';
import renderStars from "../util/renderStars";

const TextContent = styled('div')({
    padding: '17px 27px 17px 27px',
    color: colors.white,
    textAlign: 'center',
});

const TypographyWithDotIcon = styled(Typography)({
    marginBottom: 17,
    color: colors.white,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '128%',

    '& svg': {
        marginRight: 6,
    },
});

const TypographyMain = styled(Typography)({
    marginBottom: 17,
    color: colors.white,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '129%',
});

const TypographyComment = styled(Typography)({
    fontSize: 10,
    color: colors.white,
    fontWeight: 400,
    lineHeight: '150%',
});

const SessionChipsSection = styled('section')({

    display: 'flex',
    gap: 2,

    overflowX: 'auto',

    "::-webkit-scrollbar": {
        display: "none"
    },

    "& > *:first-child": {
        marginLeft: 17
    },

    "&:nth-last-child(1)": {
        paddingRight: "17px"
    }
});

const TypographyAboveChip = styled(Typography)({
    marginLeft: 10,
    marginBottom: 4,
    color: colors.white,
    fontSize: 10,
    fontWeight: 700,
    lineHeight: '130%',
});

const SessionChip = styled(Chip)({
    width: 117,
    height: 71,

    flexDirection: 'column',

    background: 'rgba(255, 255, 255, 0.14)',
    borderRadius: 27,

    color: 'inherit',
});

const SessionChipSkeleton = styled(Skeleton)({
    backgroundColor:colors.transparentWhite,
    width: 117,
    height: 71,
    borderRadius: "27px",
    alignSelf: "flex-end",
    flexShrink: 0
})

const TypographyChip = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '129%',

    '& svg': {
        marginLeft: 10,
        color: colors.yellow
    },
});

const FeedbackTitle = ({behaviorData, sessionHistory}) => {
    const dotColors = {
        green: colors.green,
        red: colors.red,
    };

    return (
        <div style={{paddingBottom: 17, color: colors.white}}>
            <TextContent>
                <TypographyWithDotIcon>
                    <ColoredDot fill={behaviorData["is_positive"] ? dotColors.green : dotColors.red}/>
                    {behaviorData.value}
                </TypographyWithDotIcon>
                <TypographyMain>{behaviorData.title}</TypographyMain>
                <TypographyComment>
                    {behaviorData.description}
                </TypographyComment>
            </TextContent>
            <SessionChipsSection>
                {sessionHistory.length > 0 ?
                    sessionHistory.map(({name, period, score}, index) => (
                        <div key={`${name}-${index}`}>
                            <TypographyAboveChip>
                                {name}
                            </TypographyAboveChip>
                            <SessionChip label={
                                <>
                                    <TypographyChip>
                                        {score !== null ? score : "–"}
                                        {score !== null ? renderStars(Math.round(score)) : null}
                                    </TypographyChip>
                                    <TypographyComment>
                                        {period}
                                    </TypographyComment>
                                </>
                            }/>
                        </div>

                    ))
                    :
                    <>
                        <SessionChipSkeleton variant={"rectangular"}/>
                        <SessionChipSkeleton variant={"rectangular"}/>
                        <SessionChipSkeleton variant={"rectangular"}/>
                    </>
                }

            </SessionChipsSection>
        </div>
    );
};

export default FeedbackTitle;
