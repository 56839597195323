import { default as axios } from 'axios';
import customHistory from '../history';
import { SERVER_URI_API } from './urls';

export const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = SERVER_URI_API;
axiosInstance.defaults.headers = { Authorization: `Bearer ${localStorage.getItem('auth-token')}` };

axiosInstance.interceptors.request.use(
    async (config) => {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${localStorage.getItem('auth-token')}`,
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !localStorage.getItem('refresh-token')) {
            customHistory.push('/auth', { state: { form: true } });
            return Promise.reject(error);
        }

        if (
            error.response?.status === 401 &&
            originalRequest.url === `auth/refresh-token/` &&
            error.response.data.code === 'token_not_valid'
        ) {
            localStorage.removeItem('auth-token');
            localStorage.removeItem('refresh-token');
            customHistory.push('/auth', { state: { form: true } });
            return Promise.reject(error);
        }
        if (
            error.response?.status === 401 &&
            !originalRequest._retry &&
            error.response.data.code === 'token_not_valid'
        ) {
            originalRequest._retry = true;
            return axiosInstance
                .post(`auth/refresh-token/`, {
                    refresh: localStorage.getItem('refresh-token'),
                })
                .then((res) => {
                    if (res?.status === 200) {
                        localStorage.setItem('auth-token', res.data.access);
                        localStorage.setItem('refresh-token', res.data.refresh);
                        axiosInstance.defaults.headers['Authorization'] =
                            'Bearer' + res.data.access;
                        return axiosInstance(originalRequest);
                    }
                });
        }
        return Promise.reject(error);
    },
);
